import React from "react";
import { ModalPopup } from "isuppli-react-components";

const OnboardingInvitationDetails = ({
  onClose,
  invitation,
  onAccept,
  onReject,
  additionalDetails,
}) => {
  const {
    registeredName,
    registrationNumber,
    vatNumber,
    tradingName,
    generalEmail,
    companyType,
  } = invitation;
  return (
    <ModalPopup
      onClose={onClose}
      heading="ONBOARDING INVITATION"
      subheading="You have been invited to onboard as a supplier"
      hasCancel={false}
      buttons={
        [{
          key: "cancel",
          label: "Reject",
          color: "secondary",
          onClick: () => { onReject(); onClose(); },
        },
        {
          key: "accept",
          label: "Accept",
          color: "primary",
          onClick: () => { onAccept(); onClose(); },
        }]
      }
    >
      <div className="row px-2 pb-2">
        <div className="col-12 col-lg-6 pt-5">
          <h2>Onboarding Invitation Details</h2>
          <div className="d-flex">
            <div className="w-50 pr-3 pt-2 font-weight-bold">
              Registered Name:
            </div>
            <div className="pt-2 ">{registeredName}</div>
          </div>

          {tradingName == null ? null : (
            <div className="d-flex">
              <div className="w-50 pr-3 pt-2 font-weight-bold">
                Company Trading Name:
              </div>
              <div className="pt-2 ">{tradingName}</div>
            </div>
          )}

          {companyType == null ? null : (
            <div className="d-flex">
              <div className="w-50 pr-3 pt-2 font-weight-bold">
                Company Type:
              </div>
              <div className="pt-2 ">{companyType}</div>
            </div>
          )}

          <div className="d-flex">
            <div className="w-50 pr-3 pt-2 font-weight-bold">
              Registration Number:
            </div>
            <div className="pt-2 ">{registrationNumber}</div>
          </div>

          {vatNumber == null || vatNumber === "" ? null : (
            <div className="d-flex">
              <div className="w-50 pr-3 pt-2 font-weight-bold">
                VAT Number:
              </div>
              <div className="pt-2 ">{vatNumber}</div>
            </div>
          )}

          {generalEmail == null ? null : (
            <div className="d-flex">
              <div className="w-50 pr-3 pt-2 font-weight-bold">
                General Business Email Address:
              </div>
              <div className="pt-2 ">{generalEmail}</div>
            </div>
          )}
        </div>
      </div>

      {additionalDetails == null ? null : (
        <div className="d-flex">
          <div className="w-50 pl-3 pt-2 font-weight-bold">
          I confirm that by qualifying for a payment term of 15 days or less, we acknowledge our status as a Supplier Development (SD) beneficiary.
          </div>
        </div>
      )}
    </ModalPopup>
  );
};

export default OnboardingInvitationDetails;
